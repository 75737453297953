import { CartType } from "@idcms/store";
import { calculateCartPrice } from "./calculateCartPrice";

export const calculateShippingByRate = (cart: CartType) => {
    const totals = cart.items.reduce((p, c) => {
        p.quantity += c.qty
        p.subtotal += c.qty * calculateCartPrice(c)
        p.weight += c.qty * (c.variant ? c.variant.weight : c.product.weight)
        return p
    }, {
        quantity: 0,
        subtotal: 0,
        weight: 0
    })
    if ((cart.freeShippingAmount || cart.freeShippingAmount === 0) && (cart.freeShippingAmount <= cart.total)) {
        return 0
    } else {
        return cart.shippingRates?.find(sr => (sr.minWeight <= totals.weight) && (sr.maxWeight > totals.weight))?.rate
    }

}
