import { CartItemType } from "@idcms/store"

export const getPriceByVolume = (cartItem: CartItemType) => {
    const prices = cartItem.variant?.prices?.filter(o => o.minQty <= cartItem.qty)

    if (prices && prices.length > 0)
        return prices[prices.length - 1].price
    else
        return 0.00
}
