import { createTheme } from '@mui/material/styles';
import { Roboto } from "next/font/google"

const roboto = Roboto({
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'], display: 'swap', fallback: ['Roboto'],
    weight: ["100", "300", "400", "500", "700", "900"]
})

export const getTheme = () => {
    return Theme
}


export const Theme = createTheme({
    components: {
        MuiInputBase: {
            defaultProps: {
                size: 'small'
            },
            styleOverrides: {
                root: ({ theme }) => theme.unstable_sx({
                    backgroundColor: "#f8f8f8",
                    "& > fieldset": {},
                    ":hover": {
                        "& > fieldset": { boxShadow: '0px 0px 8px #1E2F9739' },
                    },
                }),
                input: ({ theme }) => theme.unstable_sx({
                    color: `${theme.palette.primary.dark} !important`
                }),
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: ({ theme }) => theme.unstable_sx({
                    // backgroundColor: theme.palette.primary.main + 80,
                    opacity: 1,
                }),
            }
        },
        MuiModal: {
            styleOverrides: {
                backdrop: ({ theme }) => theme.unstable_sx({
                    // backgroundColor: theme.palette.primary.main + 99,
                }),
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: ({ theme }) => theme.unstable_sx({
                    color: `${theme.palette.primary.dark} !important`
                }),
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                svg: ({ theme }) => theme.unstable_sx({
                    transform: 'scale(1.4)'
                }),
            }
        },

        // MuiSelect: {
        //     styleOverrides: {
        //         select: ({ theme }) => theme.unstable_sx({
        //             paddingBottom: '0px',
        //             height: "30px !important"
        //         }),
        //     }
        // },
        MuiFormLabel: {
            styleOverrides: {
                root: ({ theme }) => theme.unstable_sx({
                    color: `${theme.palette.primary.dark}`,
                    fontSize: '16px !important',
                }),
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: ({ theme }) => theme.unstable_sx({
                    marginTop: '4px',
                    // border: `1px solid ${theme.palette.primary.main}`
                }),
                listbox: ({ theme }) => theme.unstable_sx({
                    color: `${theme.palette.primary.dark} !important`
                }),
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: ({ theme }) => theme.unstable_sx({
                    marginTop: '4px', border: `1px solid ${theme.palette.primary.main}`,
                }),

            }
        },
        MuiButton: {
            defaultProps: {
                size: 'medium'
            },
            styleOverrides: {
                root: ({ theme }) => theme.unstable_sx({
                    fontWeight: 500
                }),
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: ({ theme }) => theme.unstable_sx({
                    color: theme.palette.primary.main,
                })
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: ({ theme }) => theme.unstable_sx({
                    backgroundColor: '#fff !important',
                    margin: 0,
                    px: .5,
                    pt: .25
                })
            }
        }
    },
    typography: {
        fontFamily: roboto.style.fontFamily,
        // h2: {
        //     fontSize: '20px',
        //     fontWeight: '600'
        // },
        // h3: {
        //     fontSize: '18px',
        //     fontWeight: '600'
        // },
        // h4: {
        //     fontSize: '16px',
        //     fontWeight: '500'
        // },
        // h5: {
        //     fontSize: '14px',
        //     fontWeight: '500'
        // },
        // h1: {
        //     fontSize: '25px',
        //     fontWeight: '600'
        // },
        // h6: {
        //     fontWeight: 300,
        //     fontSize: '12px',
        //     letterSpacing: '.5px',
        //     marginBottom: '4px !important'
        // },
        // body2: {
        //     fontSize: '12px'
        // },
        // body1: {
        //     fontSize: '12px',
        //     lineHeight: '12px'
        // },
        button: {
            fontSize: '14px'
        }
    },
    palette: {
        primary: {
            main: '#192357',
            light: '#19235799'
            // dark: '#0F66A0',
        },
        error: {
            main: '#EA0000'
        },
        secondary: {
            // main: '#407D91',
            light: '#5e88b2',
            main: '#5577A0',
            dark: '#4F6E96'
        },
        info: {
            main: '#fff',
            dark: '#f2f2f2'
        },
        warning: {
            main: '#D14415'
        }


    },
});

