import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/store';
import { CartItemType, CartType, CouponType, ShippingMethodType, ShippingRateType, TaxRateType } from '@idcms/store';
import { calculateCartTotals } from './calculateCartTotals';

const initialState: CartType = {
    customerDiscountPercent: 0,
    freeShippingAmount: 0,
    shippingMethod: undefined,
    coupons: [],
    discount: 0,
    quantity: 0,
    items: [],
    shipping: 0,
    shippingRates: undefined,
    subtotal: 0,
    tax: 0,
    taxRate: undefined,
    total: 0,
    weight: 0
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItemToCart: (state, action: PayloadAction<CartItemType>) => {
            const item = state.items.find(o =>
                (action.payload.variant && o.variant) ?
                    o.id === action.payload.id &&
                    o.product.id === action.payload.product.id
                    && o.variant.id === action.payload.variant.id :
                    o.id === action.payload.id &&
                    o.product.id === action.payload.product.id)

            if (item) {
                if (action.payload.qty > 0) {
                    item.qty = action.payload.qty
                    item.comment = action.payload.comment
                } else {
                    // Remove from cart
                    item.qty = 0
                    // const index = state.items.indexOf(item, 0);
                    // if (index > -1) {
                    //     state.items.splice(index, 1);
                    // }
                }
            } else {
                if (action.payload.qty > 0) {
                    state.items.push(action.payload)
                }
            }

            calculateCartTotals(state)
        },
        addItemToCartWithoutReplace: (state, action: PayloadAction<CartItemType>) => {
            const availableQty = Math.min(action.payload.variant ? action.payload.variant.qty : action.payload.product.qty, action.payload.qty);

            action.payload.qty = availableQty

            const item = state.items.find(
                o => (action.payload.variant && o.variant) ?
                    o.product.id === action.payload.product.id
                    && o.variant.id === action.payload.variant.id :
                    o.product.id === action.payload.product.id)
            if (item) {
                if (action.payload.qty > 0) {
                    item.qty += action.payload.qty
                }
                if (item.qty > (action.payload.variant ? action.payload.variant.qty : action.payload.product.qty)) {
                    item.qty = (action.payload.variant ? action.payload.variant.qty : action.payload.product.qty)
                }

            } else {
                if (action.payload.qty > 0) {
                    state.items.push(action.payload)
                }
            }

            calculateCartTotals(state)
        },
        addShipping: (state, action: PayloadAction<ShippingRateType[] | undefined>) => {
            state.shippingRates = action.payload
            calculateCartTotals(state)
        },
        addShippingMethod: (state, action: PayloadAction<ShippingMethodType | undefined>) => {
            state.shippingMethod = action.payload
            calculateCartTotals(state)
        },
        addShippingAmount: (state, action: PayloadAction<number>) => {
            state.shippingAmount = action.payload
            calculateCartTotals(state)
        },
        addFreeShippingAmount: (state, action: PayloadAction<number | undefined>) => {
            state.freeShippingAmount = action.payload
            calculateCartTotals(state)
        },
        addTax: (state, action: PayloadAction<TaxRateType[] | undefined>) => {
            if (action.payload && action.payload.length > 0) {
                state.taxRate = action.payload[0]
            } else {
                state.taxRate = undefined
            }
            calculateCartTotals(state)
        },
        applyCoupon: (state, action: PayloadAction<CouponType | undefined | null>) => {
            if (action.payload && action.payload.code) {
                const existing = state.coupons?.some(o => o.coupon.code === action.payload?.code)
                if (!existing) {
                    state.coupons?.push({
                        coupon: action.payload,
                        couponId: action.payload.id,
                        appliedAmount: 0
                    })
                    calculateCartTotals(state)
                }
                calculateCartTotals(state)
            }
        },
        applyDiscount: (state, action: PayloadAction<CartType | undefined | null>) => {
            if (action.payload) {
                state.customerDiscountPercent = action.payload.customerDiscountPercent
                calculateCartTotals(state)
            }
            calculateCartTotals(state)
        },
        removeCoupon: (state, action: PayloadAction<CouponType | undefined | null>) => {
            const index = state.coupons?.findIndex(o => o.coupon.code === action.payload?.code);
            if (index && index > -1) {
                state.coupons?.splice(index, 1)
                calculateCartTotals(state)
            }
            if (index == 0) {
                state.coupons?.shift()
                calculateCartTotals(state)
            }
            calculateCartTotals(state)
        },
        clearCart: () => initialState,
        removeItemFromCart: (state, action: PayloadAction<CartItemType>) => {
            const item = state.items.find(
                o => (action.payload.variant && o.variant) ?
                    o.product.id === action.payload.product.id
                    && o.variant.id === action.payload.variant.id :
                    o.product.id === action.payload.product.id)

            if (item) {
                const index = state.items.indexOf(item, 0);
                if (index > -1) {
                    state.items.splice(index, 1);
                }
            }

            calculateCartTotals(state)
        }
    },
})

export const {
    applyDiscount,
    addItemToCart,
    addItemToCartWithoutReplace,
    addShipping,
    addShippingAmount,
    addFreeShippingAmount,
    addTax,
    applyCoupon,
    removeCoupon,
    clearCart,
    removeItemFromCart,
    addShippingMethod
} = cartSlice.actions;

export const selectCart = (state: RootState) => state.cart

export const cartReducer = cartSlice.reducer