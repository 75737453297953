import { calculateCartPrice } from "./calculateCartPrice";
import { calculateShippingByRate } from "./calculateShippingByRate";
import { CartType } from "@idcms/store";


export const calculateCartTotals = (cart: CartType) => {
  const totals = cart.items.reduce((p, c) => {
    p.quantity += c.qty
    p.subtotal += c.qty * calculateCartPrice(c)
    p.weight += c.qty * (c.variant ? c.variant.weight : c.product.weight)
    return p
  }, {
    quantity: 0,
    subtotal: 0,
    weight: 0
  })
  cart.discount = 0

  cart.quantity = totals.quantity
  cart.weight = totals.weight

  cart.shipping = 0
  cart.shippingAmount = cart.shippingAmount

  if (cart.shippingMethod?.type === 'pick-up') {
    cart.shipping = 0
  }
  if (cart.shippingAmount && cart.shippingMethod?.type === 'terminal') {
    cart.shipping = cart.shippingAmount
  }
  if (!cart.shippingMethod || cart.shippingMethod.type === 'address') {
    cart.shipping = calculateShippingByRate(cart) ?? 0
  }

  cart.subtotal = totals.subtotal
  cart.tax = (cart.taxRate ? (cart.subtotal + cart.shipping) * cart.taxRate.rate / 100 : 0)

  const grossAmount = cart.subtotal + (cart.shippingAmount ?? cart.shipping) + cart.tax

  let customerDiscountAmount = 0
  let customerDiscountTaxAmount = 0
  cart.items.filter(i => i.product.nonDiscountable !== true).forEach(i => {
    customerDiscountAmount += (i.qty * calculateCartPrice(i)) * (cart.customerDiscountPercent ?? 0) / 100
    customerDiscountTaxAmount = cart.taxRate?.rate ? (customerDiscountAmount / 100) * cart.taxRate.rate : 0
  })

  cart.tax -= customerDiscountTaxAmount
  cart.discount = customerDiscountAmount

  cart.coupons?.filter(c => c.coupon.type === "percent").forEach(c => {
    c.appliedAmount = (grossAmount * c.coupon.value) / 100
    cart.discount += c.appliedAmount
    cart.tax = cart.tax - (cart.taxRate ? c.appliedAmount * cart.taxRate.rate / 100 : 0)
  })

  cart.coupons?.filter(c => c.coupon.type === "fixed").forEach(c => {
    c.appliedAmount = Math.min(c.coupon.value, grossAmount - cart.discount)
    cart.discount += c.appliedAmount
    cart.tax = cart.tax - (cart.taxRate ? c.appliedAmount * cart.taxRate.rate / 100 : 0)
  })

  let freeShippingApplied = false
  cart.coupons?.filter(c => c.coupon.type === "free-shipping").forEach(c => {
    if (!freeShippingApplied) {
      c.appliedAmount = Math.min((cart.shipping), grossAmount - cart.discount)
      cart.shippingAmount ? (cart.discount += cart.shippingAmount) : (cart.discount += c.appliedAmount)
      cart.tax = cart.tax - (cart.taxRate ? cart.shipping * cart.taxRate.rate / 100 : 0)
      freeShippingApplied = true

    }
  })

  if (cart.tax < 0) { cart.tax = 0 }
  cart.total = cart.subtotal + (cart.shipping) - cart.discount + (cart.taxRate?.taxNotIncluded ? cart.tax : 0)
  if (cart.total < 0) { cart.total = 0 }

} 