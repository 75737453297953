import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store/store';
import { LoggedInAccountType } from '@idcms/store';

const initialState: LoggedInAccountType = {
    isLoggedIn: false,
    customerGroups: []
};

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        performLogin: (state, action: PayloadAction<LoggedInAccountType>) => {
            state.isLoggedIn = true
            state.email = action.payload.email
            state.firstName = action.payload.firstName
            state.lastName = action.payload.lastName
            if (action.payload.token)
                state.token = action.payload.token
        },
        logout: (state) => {
            state.isLoggedIn = false
            state.email = undefined
            state.firstName = undefined
            state.lastName = undefined
            state.token = undefined
        }
    }
})

export const {
    performLogin,
    logout,
} = accountSlice.actions;

export const selectAccount = (state: RootState) => state.account;

export const accountReducer = accountSlice.reducer