import { CartItemType } from "@idcms/store"
import { getPriceByVolume } from "./getPriceByVolume"

export const calculateCartPrice = (cartItem: CartItemType) => {
    const price = cartItem.variant
        ? cartItem.variant.prices && cartItem.variant.prices.length > 0 ? getPriceByVolume(cartItem) : cartItem.variant.price
        : cartItem.product.price
    return price
}

export const calcualteCartOriginalPrice = (cartItem: CartItemType) => {
    const price = cartItem.variant
        ? cartItem.variant.prices && cartItem.variant.prices.length > 0 ? getPriceByVolume(cartItem) : cartItem.variant.originalPrice
        : cartItem.product.originalPrice

    return price
}