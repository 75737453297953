// @ts-nocheck
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { SessionProvider, useSession } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { useRouter } from "next/router";
import { useEffect } from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { client } from '../components/client';
import { getTheme } from '../components/layout/Theme';
import { persistor, store } from '../components/store/store';
import nextI18NextConfig from '../next-i18next.config.js';
import '../styles/globals.scss';

function App({ Component, pageProps }: AppProps) {
  return (
    <SessionProvider refetchOnWindowFocus={false}>
      <SessionApp Component={Component} pageProps={pageProps} />
    </SessionProvider>
  )
}

function SessionApp({ Component, pageProps }: AppProps) {
  let theme = getTheme()

  theme = responsiveFontSizes(theme);
  const { data: session } = useSession()

  if (session?.user.token) {
    localStorage.setItem('token', session.user.token);
  }

  const router = useRouter()
  useEffect(() => {
    router.prefetch = async () => { }
  }, [router])

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Component {...pageProps} />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  )
}

export default appWithTranslation(App, nextI18NextConfig)